import React, { useState } from 'react';
import {
  Card, Button, Box, Typography
} from '@mui/material';
import { makeStyles, TextField } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles({
  button: {
    // width: "60%",
    // color: "white",
    // margin: "10px",
    // 'padding-top': '64px'
    // 'background-color': "#e87a00"
  },
  box: {
    // width: "50%",
    // display: "inline-block",
    // padding: "10px",
    // margin: "20px",
  },
  field: {
    // margin: "10px",
  },
  grid: {
    // height: "100vh"
  },
  font: {
    // fontSize: 2
  }
});

function ResetUsername(props) {
  const classes = useStyles();
  const [emailSent, setemailSent] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const forgotUsername = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios.post(process.env.REACT_APP_BASE_URL + '/forgot_username',
      {
        "email": e.target[0].value
      }).then((response) => {
        console.log(response);
        setemailSent(true);
        setLoading(false)
      }).catch((error) => {
        console.log(error);
        setError(true);
        setLoading(false);
      })
  };

  return (
    <>
      <Box className='auth-wrapper'> 
        <Box mb={4}>
          <Typography variant="h1">Reset Username</Typography>
          <Typography variant="subtitle1">Please enter your email address below, and a copy of your username will be sent shortly.</Typography>
        </Box>
        
        <form onSubmit={forgotUsername}>
          <Box mb={2}>
            <TextField className={classes.field} fullWidth id="outlined-basic" label="Email Address" />
            {emailSent ? <Alert className={classes.errorBox} severity="success">You username has been sent to your email.</Alert> : null}
            {error ? <Alert className={classes.errorBox} severity="error">This value is not a valid email address.</Alert> : null}
          </Box>
          <Box mt={2}>
            <Button 
              variant="contained" 
              size='large'
              color="primary" 
              type="submit"
              fullWidth
              disableElevation
            >
             {loading ? <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="loader" height="20" /> : "Submit"}   
           </Button>
          </Box>

          <Box mt={1}>
            <Button 
              variant="outlined" 
              size='large'
              color="primary" 
              href='/'
              fullWidth
              disableElevation
            >Back</Button>
          </Box>
        </form>  
      </Box>
    </>
  )
} export default ResetUsername


