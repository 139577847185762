import Login from '../components/login';
import ResetPassword from '../components/resetPassword';
import ResetUsername from '../components/resetUsername';
import NewPassword from "../components/newPassword";
import MFACode from '../components/mfa';
import Grid from '@mui/material/Grid';
import { Box, Container, Link, makeStyles } from '@material-ui/core';
import { AppBar, Toolbar } from '@mui/material';
import mainLogo from "../resources/images/main-logo.png";


const useStyles = makeStyles({
  // grid: {
  //   minHeight: "100vh",
  //   height: "100vh",
  //   minWidth: "100%"
  // },
  // side: {
  //   backgroundColor: '#d9dbda',
  //   minHeight: "100vh",
  //   width: "30vw",
  //   paddingTop: "25vh"
  // },
  // login: {
  //   width: "70vw"
  // },
  // root: {
  //   flexGrow: 1,
  // }
  authWrapper:{
    height: "calc(100vh - 65px)",
    display: "table",
    width: "100%",
    padding: "65px 0 0"
  },
  authContainer:{
    height: "100%",
    display: "table-cell",
    verticalAlign: "middle"
  }
});

function LoginView(props) { 
    const classes = useStyles();
    let route = window.location.pathname;

    let component = renderComponents(route);

    return (
      <>
        <AppBar>
          <Toolbar>
            <Container>
              <Link>
                <img style={{paddingTop: "5px"}} height="60px" src={"https://assessmentview-logo-files.s3.amazonaws.com/images/AssessmentView-logo.png"} alt="main logo" />
              </Link>
            </Container>
          </Toolbar>
        </AppBar>

        <Box className={classes.authWrapper}>
          <Container className={classes.authContainer}>
            {component}
          </Container>
        </Box>

        {/* <div className={classes.root}>
          <Grid container justifyContent="center" className={classes.grid} direction="column" alignItems="center" spacing={0} >
            <Grid className= {classes.side} item >
              <h1>Assessment View</h1>
              <div>Sample Description</div>
            </Grid>
            <Grid className={classes.login}  item md={9}>{component}</Grid>
          </Grid>
        </div> */}
      </>

    )
}


function renderComponents(route){
    let component = '';
    if(route == 'login'){
        component = <Login />
    }else if (route.includes('/resetPassword')){
        component =  <ResetPassword />
    } else if (route == '/resetUsername'){
        component = <ResetUsername />
    } else if(route.includes('/MFACode')){
        let codeArray = route.split('/');
        console.log(codeArray);
        component = <MFACode 
        userId={codeArray[2]} session={codeArray[3]}
        /> 
    } else if(route.includes('/SetMFA')){
        component = <NewPassword />
    }else if(route.includes('/sessionexpired')){
      component = <Login sessionexpired />
    }else if (route.includes('/passwordresetsuccess')){
      component = <Login passwordresetsuccess />
    } else {
        component = <Login />
    }
    return component
}

export default LoginView