import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { navigate } from "hookrouter";
import axios from "axios";
import Password from './common/Password';

function NewPassword(props) {
  const [error, setError] = useState();
  const [checked, setChecked] = useState(false);

  const phoneNumberValidation = new RegExp(
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  );
  const passwordValidation = new RegExp(
    /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
  );

  const validation = (e, oldPassword) => {
    let noMFA = false
    if(window.location.href.includes('nomfa')){
      noMFA = true
    }
    let phoneNumber = e.target[0].value;
    let password = noMFA ? e.target[0].value : e.target[1].value;
    let confirmPassword = noMFA ? e.target[2].value : e.target[3].value;
    let errorObj = {};
    let formattedPhone = phoneNumber.replace(/[^\d\+]/g,'');

    //Commented out for now due to international validation issues. 
    if (!noMFA && phoneNumber.trim().length === 0)
      errorObj.phoneNumber = "Cell Phone Number is Required";
    else if (formattedPhone.length > 10 && !formattedPhone.includes('+'))
        errorObj.phoneNumber = "Phone Number is too long. If international, please add + before phone number."
    else  if
    (!phoneNumberValidation.test(phoneNumber))
      errorObj.phoneNumber = "Invalid Phone Number";

    if (password.trim().length === 0)
      errorObj.password = "Password is Required";
    else if (!passwordValidation.test(password))
      errorObj.password =
        "Password must meet the following:  Minimum 8 characters, 1 number, 1 capital Letter, 1 lowercase letter, 1 special character";
    else if (password.trim() === oldPassword)
      errorObj.password = "Password has to be new";

    if (confirmPassword.trim().length === 0)
      errorObj.confirmPassword = "Password Confirmation is Required";
    else if (password !== confirmPassword)
      errorObj.confirmPassword = "Password doesn't match!";

    if (!checked) errorObj.checkBox = true;

    console.log('error Object', errorObj)
    setError(errorObj);

    return Object.keys(errorObj).length === 0 ? true : false;
  };

  const formatPhone = (phoneNumber) => {
    let formattedPhone = phoneNumber.replace(/[^\d\+]/g,'');

    let finalFormat
    //Adds US country code if 10 digit standard number provided
    if(!formattedPhone.includes('+') && formattedPhone.length == 10){
      finalFormat = '+1' + formattedPhone
    } else {
      finalFormat = formattedPhone
    }
    console.log('final Fomrat', finalFormat)
    return finalFormat
  }

  const setupMfaCode = async (e) => {
    e.preventDefault();
    let route = window.location.pathname;
    let keys = route.split("/");
    let session = keys[4];
    let oldPassword = keys[3];
    let username = keys[2];

    let phoneNumber = formatPhone(e.target[0].value)

    let payload 

    if(window.location.href.includes('nomfa')){
      payload = {
        username,
        password: e.target[0].value,
        session: session,
      }
    } else {
      payload = {
        username,
        password: e.target[1].value,
        phone_number: phoneNumber,
        session: session,
      }
    }


    if (validation(e, oldPassword)) {
      await axios
        .post(
          process.env.REACT_APP_BASE_URL + "/setup_mfa", 
          payload
        )
        .then((response) => {
          if(response?.data?.auth_data?.ChallengeName == "SMS_MFA"){
            navigate(
              "/MFACode/" + username + "/" + response.data.auth_data.Session
            );
          } else if(response?.data?.Location){
            window.location.href = response.data.Location
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error?.response?.data?.err.includes("session is expired")) {
            navigate("/sessionexpired")
          }
        });
    }
  };

  //This is needed as we are using the same Password Component as the jsonFormSchema
  //If we can find a way to do a conditional onChange() in Password.js this can be removed. 
  const doNothing = () => {
  }

  return (
    <Box className="auth-wrapper">
      <Box mb={4}>
        <Typography variant="h1">Set Password</Typography>
        <Typography variant="subtitle1">Password reset is required.</Typography>
      </Box>

      <form onSubmit={setupMfaCode}>
        {!window.location.href.includes("nomfa") ?
        <Box mb={2}>
          <TextField
            error={error?.phoneNumber ? true : false}
            helperText={error?.phoneNumber}
            fullWidth
            label="Cell Phone Number*"
            // className={"MuiInput-underline"}
          />
        </Box>
        : null
        }
        {/* New Fields */}
        <Password label="Password*" onChange={() => doNothing()} error={error?.password ? true : false} helperText={error?.password} value={props.formData} />
        <Password label="Confirm Password*"  onChange={() => doNothing()} error={error?.confirmPassword ? true : false} helperText={error?.confirmPassword} value={props.formData} />
        <Box mb={2}>
          <FormControl required error={error?.checkBox ? true : false}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label="I Agree To Receive Text Messages From Behavior Imaging."
            />
            {error?.checkBox ? (
              <FormHelperText>
                You must agree to receive text messages from Behavior Imaging.
              </FormHelperText>
            ) : null}
          </FormControl>
        </Box>

        <Box mt={2}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            fullWidth
            disableElevation
          >
            Send
          </Button>
        </Box>

        <Box mt={1}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            href="/"
            fullWidth
            disableElevation
          >
            Back
          </Button>
        </Box>
      </form>
    </Box>
  );
}
export default NewPassword;
