import * as React from 'react';
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";
import CustomForm from "./customForm";
import { navigate } from 'hookrouter';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { Card, Button, Typography, Divider, Link} from '@mui/material';
import { Box } from '@material-ui/core';
import logo from "../resources/images/logo-powered-by.png";
import Password from './common/Password';

const styles = theme => ({
  box: {
    // width: "50%",
    // display: "inline-block",
    // padding: "10px",
    // margin: "z0px"
  },
  button: {
    // width: "60%",
    // color: "white",
    // margin: "10px",
    // 'background-color': "#e87a00"
  },
  errorBox: {
    margin: '5px',
    // width: "100%"
  },
  grid: {
    height: "100vh"
  },

});

const fields = {
  password: (props) => (
    <Password label={props.schema.title} value={props.formData} {...props} />
  )
} 

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.formData,
      schema: this.schema,
      uiSchema: this.uiSchema,
      loaded: false,
      loginloading: false,
      errorAlert: false,
      tempPasswordExpired: false,
      logo: "https://noda-logo-files.s3.amazonaws.com/images/nodalogoclear.png"
    }
    this.getLoginform = this.getLoginForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.getLoginForm();
  }

  async getLoginForm() {
    await axios.post(process.env.REACT_APP_LOGIN_API,
      {
        'sysname': 'bis_login_form'
      }).then((response) => {
        // let formObject = JSON.parse(response.data.data);
        let formObject = response.data.data;
        this.setState({ schema: formObject.form_json });
        this.setState({
          uiSchema: {
            ...formObject.ui_schema,
            password: {
              ...formObject.ui_schema.password,
              "ui:field": "password",
            },
          },
        });
        this.setState({ formData: formObject.form_data });
        this.setState({ loaded: true })
      })
  }

  async handleSubmit({ formData }) {
    this.setState({loginloading: true});
    this.setState({errorAlert: false});
    this.setState({formData})
    let trimmedUsername = formData.username.trim().toLowerCase(); 
    await axios.post(process.env.REACT_APP_BASE_URL + '/auth_mfa',
      {
        'username': trimmedUsername,
        'password': formData.password
      }).then((response) => {
        this.setState({loginloading: false})
        if(response.data.auth_data?.ChallengeName == 'NEW_PASSWORD_REQUIRED' || response.data.auth_data?.ChallengeName == "MFA_SETUP"){
          navigate(`/SetMFA/${trimmedUsername}/${formData.password}/${response.data.auth_data.Session}`);
        } else if (response.data.auth_data?.ChallengeName == 'SMS_MFA'){
        console.log('authmfa response', response);
        navigate ('/MFACode/' + trimmedUsername + '/' + response.data.auth_data.Session)
        } else if(response?.data?.Location){
          // let token = response?.data.auth_data.AuthenticationResult?.AccessToken;
          // let token = location.split('=').pop();
          window.location.href = response.data.Location
        }
      }).catch((error) => {
        this.setState({loginloading: false})
        console.log('wrong pass', error);
        if(error?.response?.data?.err.includes("Temporary password")){
          this.setState({ tempPasswordExpired: true })
        }else{
          this.setState({ errorAlert: true })
        }
      });

  }

  

  getLogo() {
    let route = window.location.pathname;
    // if(route.includes('Bis')){
    //   let logo = 'https://noda-logo-files.s3.amazonaws.com/images/nodalogoclear.png';
    //   this.setState({logo: logo})

    // } else if(route.includes('SARRC')){
    //  let logo = 'https://www.azgives.org/?downloadFile=1&table=organizations&field=logo&check=be03e2246c8ed7dee2f182de4f086901&key=3228&openGraph=1'
    //  this.setState({logo: logo})
    // }

    this.setState({ logo: 'https://noda-logo-files.s3.amazonaws.com/images/nodalogoclear.png' })
  }

  //Add custom error messsages to default validation error messages 
  transformErrors(errors) {
    return errors.map(error => {
      if (error.name === "pattern") {
        error.message = "Only digits are allowed"
      }
      return error;
    });
  }

  setFormData(updatedformData) {
    this.setState({ formData: updatedformData });
    console.log('setFormData', updatedformData)
  }



  render() {
    const { classes } = this.props;
    if (!this.state.loaded) {
      return (
        <Grid container justifyContent="center" className={classes.grid} direction="column" alignItems="center" spacing={2}>
          <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" height="20"/> 
        </Grid>
      )
    }

    return (
      <>
        <Box className='login-wrapper'>
          <CustomForm
            schema={this.state.schema}
            uiSchema={this.state.uiSchema} 
            formData={this.state.formData} 
            key={this.state.updateKey} 
            validate={this.validate} 
            transformErrors={this.transformErrors}
            onSubmit={this.handleSubmit}
            fields={fields}
          >
            {this.state.errorAlert ?
              <Alert className={classes.errorBox} severity="error">Invalid username or password</Alert>
              : null 
            }
            {this.state.tempPasswordExpired ?
              <Alert className={classes.errorBox} severity="error">Temporary password has expired and must be reset by an administrator.</Alert>
              : null 
            }
           {this.props?.sessionexpired?
              <Alert className={classes.errorBox} severity="error">Session expired, please log in</Alert>
              : null 
            }
           {this.props?.passwordresetsuccess?
              <Alert className={classes.errorBox} severity="success">Password has been reset. Please log in</Alert>
              : null 
            }


            <Box my={2}>
              <Button 
                size="large"
                variant="contained" 
                color="primary" 
                type="submit"
                fullWidth
                disableElevation
              >
                {this.state.loginloading ? <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="button loader" height="20" /> : 'Login'}
              </Button>
            </Box>
          </CustomForm>

          <div>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant='text'
                  href='/resetPassword' 
                  color="primary"
                >Forgot Password?</Button>
              </Grid>
              <Grid item>
                <Button 
                  variant='text'
                  href='/resetUsername' 
                  color="primary"
                >Forgot Username?</Button>
              </Grid>
            </Grid>

            {/* <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
              <Typography mr={1}>Don't have an Account?</Typography>
              <Button variant='text' href='/register'>Sign Up</Button>
            </Box> */}

            <Box mt={1}>
              <Divider orientation='horizontal' variant='fullWidth' />
            </Box>

            <Box mt={3} textAlign="right">
              <Link>
                <img src={logo} alt="powered by logo" />
              </Link>
            </Box>

          </div>
        </Box>
      </>

    )
  }
} export default withStyles(styles, { withTheme: false })(Login)