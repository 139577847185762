import React, { useState } from 'react';
import {
  Card, Button, Typography
} from '@mui/material';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { navigate } from 'hookrouter';

const styles = theme => ({
  button: {
    width: "60%",
    color: "white",
    margin: "10px",
    // 'background-color': "#e87a00"
  },
  box: {
    width: "60%",
    display: "inline-block",
    padding: "10px",
    margin: "20px"
  },

});



class MFACode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    await axios.post(process.env.REACT_APP_BASE_URL +'/challenge_mfa',
      {
        'username': this.props.userId,
        'sms_code': e.target[0].value,
        'session': this.props.session
      }).then((response) => {
        console.log('success', response);
        window.location.href = response.data.Location

        // let token = response.data.Location.split('=').pop();
        // window.location.href = process.env.REACT_APP_REDIRECT_URL + token;
      }).catch((error) => {
        if (error?.response?.data?.err.includes("session expired")) {
          navigate("/sessionexpired")
        }
        this.setState({ errorAlert: true });
        this.setState({ errorMessage: error?.response?.data?.message })
        console.log('wrong pass', error);
        console.log('resosne?', error?.response.err)
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Box className='auth-wrapper'>
        <Box mb={4}>
          <Typography variant="h1">MFA Code</Typography>
          <Typography variant="subtitle1">Please enter the MFA Code Sent to Your Cell Phone.</Typography>
        </Box>

        {this.state.errorAlert ?
          <Box mb={2}>
            <Alert className={classes.errorBox} severity="error">{this.state.errorMessage}</Alert>
          </Box>
          : null
        }

        <form onSubmit={this.handleSubmit}>
          <Box mb={2}>
            <TextField autoFocus fullWidth label="MFA Code" />
          </Box>
          <Box mt={2}>
            <Button 
              variant="contained" 
              size='large'
              color="primary" 
              type="submit"
              fullWidth
              disableElevation
            >Send</Button>
          </Box>

          <Box mt={1}>
            <Button 
              variant="outlined" 
              size='large'
              color="primary" 
              href='/'
              fullWidth
              disableElevation
            >Back</Button>
          </Box>
        </form>
      </Box>
    )
  }
} export default withStyles(styles, { withTheme: false })(MFACode)
