import React, { useRef, useState } from "react";
import { filter, uniqWith, isEqual } from "lodash";
import { makeStyles, TextField } from '@material-ui/core';
import { withTheme } from "@rjsf/core";
import { Theme as MaterialUITheme } from "@rjsf/material-ui";

//currently using MUI4, we need to update the import to mui5 once it it released by rjsf
const Form = withTheme(MaterialUITheme);

const useStyles = makeStyles({
  card: {
    padding: "20px",
  }
});

function CustomForm(props) {
  const classes = useStyles();
  const myRef = useRef(null);

  const blurHandler = (...args) => {
    const $this = myRef.current;
    const field = args[0].split("_")[1];
    const { formData, uiSchema, errors, errorSchema } = $this.state;

    if (uiSchema[field] && uiSchema[field].validateOnBlur) {
      const { errors: _errors, errorSchema: _errorSchema } = $this.validate(
        formData
      );

      const prevOtherFieldErrors = filter(
        errors,
        (error) => error["property"] !== `.${field}`
      );

      const fieldErrors = filter(_errors, ["property", `.${field}`]);

      const fieldErrorSchema = _errorSchema[field];

      $this.setState({
        errors: uniqWith([...prevOtherFieldErrors, ...fieldErrors], isEqual),
        errorSchema: { ...errorSchema, [field]: fieldErrorSchema }
      });
    }
  };

  return <Form {...props} ref={myRef}  onBlur={blurHandler} />;
}

export default CustomForm;