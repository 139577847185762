import React, { useState } from 'react';
import {
  Card, Button, Box, Typography, FormControl
} from '@mui/material';
import { makeStyles, TextField } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import Password from "../components/common/Password"
import Alert from "@mui/material/Alert";
import { navigate } from 'hookrouter';

const useStyles = makeStyles({
  field: {
    // margin: "10px",
  },
  errorBox: {
    marginTop: "20px",
  },
});

function ResetPassword(props) {
  const classes = useStyles();
  const [resetPass, setResetPass] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const initiatePasswordReset = async (e) => {
    setLoading(true)
    e.preventDefault();
    setError(null);
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/initiate_reset_password",
        {
          username: e.target[0].value,
        }
      )
      .then((response) => {
        console.log(response);
        setLoading(false)
        setResetPass(true);
      })
      .catch((error) => {
        setLoading(false)
        let errObj = {}
        if(error?.response?.data?.message){
          errObj.message = error?.response?.data?.message
        } else {
          errObj.message = "This value is not a valid username."
        }
        setError(errObj)
        console.log(error.response.data.message);
      });
  };
  const resetPassword = async (e) => {
    e.preventDefault();
    console.log('mfa', e.target[0].value);
    console.log('pass', e.target[2].value);
    if (validation(e
      // , oldPassword
      )) {
        setLoading(true)

    await axios.post(process.env.REACT_APP_BASE_URL + '/reset_password',
      {
        "username": e.target[0].value,
        "code": e.target[1].value,
        "password": e.target[2].value
      }).then((response) => {
        console.log(response)
        setLoading(false)
        navigate("/passwordresetsuccess")
      }).catch((error) => {
        console.log(error);
        setLoading(false)
        let errObj = {}
        errObj.message = error.response.data.err
        setError(errObj)
      })
    };
  };

  const passwordValidation = new RegExp(
    /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/
  );

  const validation = (e
    // , oldPassword
    ) => {
    let username = e.target[0].value;
    let mfa = e.target[1].value;
    let password = e.target[2].value;
    let confirmPassword = e.target[4].value;
    let errorObj = {};

    if(username.length === 0){
      errorObj.username = "Username is Required";
    } 

    if(mfa.length === 0){
      errorObj.mfa = "MFA Code is Required"
    }
    
    if (password.trim().length === 0)
      errorObj.password = "Password is Required";
    else if (!passwordValidation.test(password))
      errorObj.password =
        "Password must meet the following:  Minimum 8 characters, 1 number, 1 capital Letter, 1 lowercase letter, 1 special character";
    // else if (password.trim() === oldPassword)
    //   errorObj.password = "Password has to be new";

    if (confirmPassword.trim().length === 0)
      errorObj.confirmPassword = "Password Confirmation is Required";
    else if (password !== confirmPassword)
      errorObj.confirmPassword = "Password doesn't match!";


    setError(errorObj);

    return Object.keys(errorObj).length === 0 ? true : false;
  };

   //This is needed as we are using the same Password Component as the jsonFormSchema
  //If we can find a way to do a conditional onChange() in Password.js this can be removed. 
  const doNothing = () => {
  }

  return (
    <>
      <Box className="auth-wrapper">
        {resetPass ? (
          <Box>
            <Box mb={4}>
              <Typography variant="h1">Reset Password</Typography>
              <Typography variant="subtitle1">
              Please enter the code that was sent to your email, then create your new password.
              </Typography>
            </Box>

            <form onSubmit={resetPassword}>
              <Box mb={2}>
                <TextField
                 className={classes.field} 
                 fullWidth 
                 id="outlined-basic" 
                 error={error?.username ? true : false} 
                 helperText={error?.username}
                 label="User Name" />
              </Box>
              <Box mb={2}>
                <TextField 
                className={classes.field}  
                fullWidth 
                id="outlined-basic" 
                error={error?.mfa ? true : false}
                helperText={error?.mfa}
                label="Code Send To Your Email" />
              </Box>
              <Password label="New Password" onChange={() => doNothing()} error={error?.password ? true : false} helperText={error?.password} value={props.formData} />
              <Password label="Confirm New Password"  onChange={() => doNothing()} error={error?.confirmPassword ? true : false} helperText={error?.confirmPassword} value={props.formData} />

              {error?.message ? (
                <Alert className={classes.errorBox} severity="error">
                  {error.message}
                </Alert>
              ) : null}
              <Box mt={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  fullWidth
                  disableElevation
                >
               {loading ? <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="button loader" height="20" /> : 'Submit'}
                </Button>
              </Box>

              <Box mt={1}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  href="/"
                  fullWidth
                  disableElevation
                >
                  Back
                </Button>
              </Box>
            </form>
          </Box>
        ) : (
          <Box>
            <Box mb={4}>
              <Typography variant="h1">Reset Password</Typography>
              <Typography variant="subtitle1">
                Please enter your Username below, and a link to reset your
                password will be sent shortly.
              </Typography>
            </Box>

            <form onSubmit={initiatePasswordReset}>
              <Grid item md={12}>
                <TextField
                  className={classes.field}
                  fullWidth
                  id="outlined-basic"
                  label="Username"
                />
              </Grid>
              {error?.message ? (
                <Alert className={classes.errorBox} severity="error">
                  {error.message}
                </Alert>
              ) : null}
              <Box mt={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                  fullWidth
                  disableElevation
                >
             {loading ? <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" alt="button loader" height="20" /> : 'Submit'}
                </Button>
              </Box>

              <Box mt={1}>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  href="/"
                  fullWidth
                  disableElevation
                >
                  Back
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Box>
    </>
  );
}
export default ResetPassword;
