import React, { useLayoutEffect } from 'react';
import CustomForm from "../components/customForm";
import {
  Card,
  CardMedia,
  Box,
  Button
} from '@mui/material';
import Grid from '@mui/material/Grid';
import axios from 'axios'
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  box: {
    width: "60%",
    display: "inline-block",
    padding: "20px",
    'padding-top': '64px'
  
  },
  button: {
    width: "60%",
    color: "white",
    margin: "10px",
    
    // 'background-color': "#e87a00"
  },
  grid: {
    height: "100vh"
  },

});

const uiSchema = {
  firstName: {
    classNames: "TIMCLASS",
    // "ui:help": "WORK?"
  }
}



const baseURL = 'https://api-nonprod.behaviorimaging.com/prod/v1/form/get_form';



//Add custom error messsages to default validation error messages 
function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === "pattern") {
      error.message = "Only digits are allowed"
    }
    return error;
  });
}


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.formData,
      schema: this.schema,
      uiSchema: this.uiSchema,
      apiTest: this.apiTest,
      dropDowns: {
        states: {},
        countries: {},
        formStrings: {}
      },
      loaded: false,
      logo: '',
      updateKey: 1
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.setFormData = this.setFormData.bind(this);
  }
  

  //Gets the JSON form, countries, and states on load
  //Need to add route check to determine api call
  async componentDidMount() {
    //get JSONform

    await this.getForms();
    await this.getLogo();
    await this.getLang();
    await this.localizeForm();
    await this.getDropDownValues();

  };

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.formData !== undefined) {
      if (prevState.formData.country !== this.state.formData.country) {
        console.log('hit did update')
        let country = Object.keys(this.state.dropDowns.country).find(key => this.state.dropDowns.country[key] === this.state.formData.country)
        this.getStates(country);
      }
    }
  };



  async handleSubmit({ formData }) {
    let formDataAdd = this.state.formData;
    formDataAdd.role = 2
    this.setState({ formData: formDataAdd })
    console.log('hitsubmit', {
      "form_id": "4",
      "formData": this.state.formData
    })
    //send form data to api, or move to the next form that needs to be completed
    await axios.post('https://api-nonprod.behaviorimaging.com/prod/v1/form/save_general_form', {
      "form_id": 4,
      "formData": this.state.formData
    })
      .then((response) => {
        console.log('submitted form', response);
        this.setState({ formData: {} })
      })
      .catch(error => {
        return error;
      })
  }

  async getLang() {
    let language = navigator.language || navigator.userLanguage;
    let config = {
      headers: {
        //  "crossorigin": true, 
        // "Access-Control-Allow-Origin": "/*/",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Content-Type": "application/json"
      }
    }
    await axios.post('https://api-nonprod.behaviorimaging.com/prod/v1/utility/language_pack',
     {
       lang: language
     }
    ).then((response) => {

      console.log('lang 2', response)
      let dropDownsObject = { states: {}, countries: {}, formStrings: {} };
      dropDownsObject.states = response.data.data.stateStrings;
      dropDownsObject.country = response.data.data.countryStrings;
      dropDownsObject.formStrings = response.data.data.formStrings;


      this.setState({ dropDowns: dropDownsObject })

    })
  };

  getLogo() {
    let route = window.location.pathname;
    // if(route.includes('Bis')){
    //   let logo = 'https://noda-logo-files.s3.amazonaws.com/images/nodalogoclear.png';
    //   this.setState({logo: logo})

    // } else if(route.includes('SARRC')){
    //  let logo = 'https://www.azgives.org/?downloadFile=1&table=organizations&field=logo&check=be03e2246c8ed7dee2f182de4f086901&key=3228&openGraph=1'
    //  this.setState({logo: logo})
    // }

    this.setState({ logo: 'https://noda-logo-files.s3.amazonaws.com/images/nodalogoclear.png' })
  }

  async getForms() {
    await axios.post(baseURL,
        {
        callingUserId: 1,
        form_type_id: 4,
        version: "1.0"
        }
    ).then((response) => {
      console.log('response', response)
      let formData = JSON.parse(response.data.data);
      this.setState({ schema: formData.form_json });
      // this.setState({ uiSchema: response.data.ui_schema });
      this.setState({ uiSchema: formData.ui_schema })
      this.setState({ formData: formData.form_data });
      }).catch(error => {
        return error;
      })
  }

  //custom validation errors
  validate(formData, errors) {

    if (this.state.formData.password !== this.state.formData.passwordConfirmation) {
      errors.passwordConfirmation.addError("Passwords don't match");
      console.log('state.formData', this.state)
    } else if (this.state.formData.email !== this.state.formData.emailConfirmation) {
      errors.emailConfirmation.addError("Email Addresses don't match");
      // } else if(this.state.formData.country !== ""){
      //   this.getStates(this.state.formData.country);
    }
    return errors;
  }


  //Loops through the localize object, and replaces any part of the string that matches the key with the proper translation string. 
  localizeForm() {
    let localize = this.state.dropDowns.formStrings;
    let stringified = JSON.stringify(this.state.schema);
    // let JSONFinalized

    // localize.forEach((item, i) => {
    //   let key = Object.keys(item)[0];
    //   stringified = stringified.replace(key, item[key]);

    // })
    for (let value in localize) {
      let localizeKey = Object.keys(localize)[value];
      stringified = stringified.replace(value, localize[value]);
    }
    // JSONFinalized = JSON.parse(stringified);
    this.setState({ schema: JSON.parse(stringified) });

    //where should this actually go? Does the uiSchema need anything besides being set in the state? 
    // this.setState({ uiSchema: uiSchema });
    console.log('localized', this.state.schema);
  }

  //checks if an enum exists for each property object. 
  //If enum exists, it will check if the enum's set language matches any formstring and pushes them all into the array then removes the initial key
  //Is there a better way than to have to loop through both objects? 
  getDropDownValues() {
    let localize = this.state.dropDowns.country;
    console.log('contries', localize)
    let jsonObject = this.state.schema;
    let property = jsonObject.properties;

    for (let key in property) {
      console.log('key', key)
      if (property[key].enum && property[key].enum.length > 0) {
        // let dropdownLang = property[key].enum[0];
        let dropdownLang = "co";
        // localize.forEach((item, i) => {
        //   let formString = Object.keys(item)[0];
        //   if (formString.includes(dropdownLang)) {
        //     property[key].enum.push(item[formString])
        //   }
        // });

        for (let value in localize) {
          if (value.includes(dropdownLang)) {
            property[key].enum.push(localize[value])
            console.log('key', Object.keys(localize)[0]);
          }
        }
        property[key].enum.splice(0, 1);
        jsonObject.properties = property;
        this.setState({ schema: jsonObject });
      }
    }
    console.log('Dropdowns', this.state)
    this.setState({ loaded: true });
  }

  getStates(country) {
    console.log(country);
    if(country){
      let countryForm = country.slice(3, country.length);
   

    if (this.state.formData && this.state.formData.country) {
      let jsonObject = {};
      jsonObject = this.state.schema;
      let localize = this.state.dropDowns.states;
      let property = jsonObject.properties;
      let dropdownLang = 'united_states'
      // let dropdownLang = country;

      jsonObject.properties.state.enum = [];
      this.setState({ schema: jsonObject })

      for (let value in localize) {
        if (value.includes(countryForm)) {
          property.state.enum.push(localize[value])
        }
      }

      // localize.forEach((item, i) => {
      //   let formString = Object.keys(item)[0];
      //   if (formString.includes(dropdownLang)) {
      //     property.state.enum.push(item[formString])
      //   }
      // });

      jsonObject.properties = property;
      this.setState({ schema: jsonObject });

      //This is currently needed to force the child component ie. CustomForm to update on state change
      let newKey = this.state.updateKey + 1;
      this.setState({ updateKey: newKey })

      console.log('gotStates', this.state)
    }
  }
  }

  setFormData(updatedformData) {
    this.setState({ formData: updatedformData });
    console.log('setFormData', updatedformData)
  }

  render() {
    const { classes } = this.props;
    // const { schema, uiSchema, formData } = this.state
    if (!this.state.loaded) {
      return (
        <Grid container justifyContent="center" className={classes.grid} direction="column" alignItems="center" spacing={2}>
        <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" height="20"/> 
         </Grid>
        )
    }

    return (
         <Box className={classes.box}>
            <img width="250" src={this.state.logo}></img>
        <CustomForm
          schema={this.state.schema}
          // FieldTemplate={customFieldTemplate}
          uiSchema={this.state.uiSchema} formData={this.state.formData} key={this.state.updateKey} validate={this.validate} transformErrors={transformErrors} onChange={e => this.setFormData(e.formData)} onSubmit={this.handleSubmit}>
          <Button variant="contained" color="primary" size="small" className={classes.button} style={{ fontSize: '12px' }} type="submit">SUBMIT</Button>
        </CustomForm>
        <div>
        Have an account? <Button  style={{ fontSize: '12px' }} href='/login' color="inherit">Login</Button>
        </div>
        </Box>
    )

  }
}

export default withStyles(styles, { withTheme: false })(Register)