import { createTheme } from '@mui/material/styles';
import { colors } from '@material-ui/core';

const dark_Orange = '#e87a00';

const bisTheme = createTheme ({
  palette: {
    common:{
      black: "#000929"
    },
    background: {
      paper: "#fff",
      default: "#fff"
    },
    primary: {
      main: "#78B4D9",
      contrastText: "#FFFFFF",
    },  
    secondary: {
      main: "#F50057"
    },
    typography: {
      fontFamily: "Inter",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      body1: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "15px",
        letterSpacing: "-0.02em"
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        size: "small"
      }
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: "#000",
          backgroundColor: "#fff"
        },
        root: {
          boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.14)"
        }
      }
    },
    MuiButton:{
      styleOverrides:{
        root:{
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '16px',
          letterSpacing: "-0.02em",
          textTransform: "capitalize"
        },
        text:{
          fontWeight: 500,
          fontSize: "14px",
          color: "#8fb0b4",
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: '#78B4D9',
          '&$checked': {
            color: '#78B4D9',
          }
        }
      }
    },

// .MuiCheckbox-colorSecondary.Mui-checked{
//   color: #9cccd2;
// }
    MuiTypography: {
      styleOverrides:{
        root:{
          fontFamily: "Inter",
        },
        h1:{
          fontWeight: 600,
          fontSize: "30px",
          lineHeight: "normal",
          letterSpacing: "-0.02em",
          color: "#000929",
        },
        body1: {
          fontWeight: 400,
          fontSize: "15px",
          letterSpacing: "-0.02em",
          lineHeight: "normal",
          color: "#000929",
        },
        subtitle1: {
          fontWeight: 400,
          fontSize: "15px",
          letterSpacing: "-0.02em",
          lineHeight: "normal",
          color: "#808494",
        }
      }
    },
    MuiDivider:{
      styleOverrides:{
        root:{
          backgroundColor: "rgba(255, 255, 255, 0.3)"
        }
      }
    }
  }
});


export default bisTheme;