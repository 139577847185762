import "./App.css";
import Form, { Theme } from "@rjsf/material-ui";
import { CssBaseline, makeStyles } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";
import { Card, Button, Grid } from "@mui/material";
import JsonForm from "./components/jsonForm";
import { useRoutes } from "hookrouter";
//material/style works for components, core/style works for buttons...
import { ThemeProvider } from "@mui/material/styles";
// import { ThemeProvider } from "@material-ui/core/styles";
import bisTheme from "./themes/bis";
// import testTheme from './theme/test';
import Login from "./components/login";
import LoginView from "./views/LoginView";
import ResetPassword from "./components/resetPassword";
import ResetUsername from "./components/resetUsername";
import Register from "./components/register";
import MFACode from "./components/mfa";
import { useState, useEffect } from "react";

const useStyles = makeStyles(() => ({
  root: {
    // height: '100vh',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // // padding: '20px 40px',
    // width: '100%',
    // fontSize: 16,
    // backgroundImage: 'url({./desktop_decor800x731.png})'
    // 'padding-left': '256px',
  },
  formfields: {
    padding: "50px",
  },
  nav: {
    width: "100%",
    positon: "static",
  },
}));

const routes = {
  "/": () => <LoginView />,
  "/Bis/Form": () => <JsonForm />,
  "/SARRC/Form": () => <JsonForm />,
  "/login": () => <LoginView />,
  "/register": () => <Register />,
  "/resetUsername": () => <LoginView />,
  "/resetPassword": () => <LoginView />,
  "/MFACode/:userId/:session": ({ userId, session }) => (
    <LoginView userId={userId} session={session} />
  ),
  "/SetMFA/:userId/:password/:session": () => <LoginView />,
  // '/MFACode/:userId': ({userId}) => <MFACode userId={userId}/>
  // '/MFACode': () => <MFACode />
  "/sessionexpired": () => <LoginView />,
  "/passwordresetsuccess": () => <LoginView />
};

let theme = bisTheme;
//Sets Theme based on route, easier way to do this as site gets larger? Does it need to be in App?
function getThemes() {
  let route = window.location.pathname;
  if (route.includes("Bis")) {
    theme = bisTheme;
    console.log("route", routes);
  }
  // else if(route.includes('SARRC')){
  //   theme= testTheme;
  // }
  else {
    theme = bisTheme;
    console.log("setTheme");
  }
}

function App() {
  const [showView, setShowView] = useState(false);
  const classes = useStyles();
  const routeResult = useRoutes(routes);

  useEffect(() => {
    localStorage.getItem("authToken")
      ? (window.location.href = process.env.REACT_APP_REDIRECT_URL)
      : 
      setShowView(true);
  }, []);

  return (
    <>
      {showView ? (
        <ThemeProvider theme={theme}>
          {/* <CssBaseline /> */}
          <div className="App">
            <div className={classes.root}>{routeResult}</div>
          </div>
        </ThemeProvider>
      ) : (
        <Grid
          container
          justifyContent="center"
          sx={{ height: "100vh" }}
          direction="column"
          alignItems="center"
          spacing={2}
        >
          <img
            src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
            height="20"
          />
        </Grid>
      )}
    </>
  );
}

export default App;
