import { IconButton, Input, InputAdornment, TextField, Box, FormControl, InputLabel, FormHelperText} from "@mui/material";
import { useState, useRef } from "react";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
})
const Password = (props) => {
  const { label, onChange, value } = props;
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const password = useRef(null)

  const handleClick = () => {
    console.log('setting focus')
    password.current.focus()
  }

  return (
    <Box>
      <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
        <InputLabel>
        {label}
        </InputLabel>
      <Input
        type={showPassword ? "text" : "password"}
        onChange={(e) => onChange(e.target.value)}

        defaultValue={value}
        label={label}
        error={props.error}
        onClick={handleClick}
        ref={password}
        sx={[
          {
            '&:after': {borderBottomColor : props.error ? 
              "#f44336 !important" : "2px solid #3f51b5 !important"}
          }
         ]}
        helpertext={props.error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {props.error?
      <FormHelperText sx={{color:"#f44336", marginLeft:"0px"}}>{props.helperText}</FormHelperText>
      : null
      }
      </FormControl>
    </Box>
  );
};

export default Password
